import { render, staticRenderFns } from "./Order.vue?vue&type=template&id=474f4cb4&scoped=true&"
import script from "./Order.vue?vue&type=script&lang=js&"
export * from "./Order.vue?vue&type=script&lang=js&"
import style0 from "./Order.vue?vue&type=style&index=0&id=474f4cb4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474f4cb4",
  null
  
)

export default component.exports